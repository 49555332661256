<template>
  <div id="ProBuys">
    <div class="ProBuy_in">
      <!-- <div class="resetpwd_top">
        <img
          class="logopc"
          src="~/assets/images/nav/logo.png"
          alt="找不到资源"
          @click="toindex"
        />
        <img
          class="logophone"
          src="~/assets/images/images_phone_show/logo_mini.png"
          alt="找不到资源"
          @click="toindex"
        />
        <span class="gang">|</span>
        <h2>购买商品</h2>
      </div> -->
      <nav-tab-part1 pagetitle="购买商品">
        <template v-slot:topRightText><span @click="goBack">返回</span></template>
      </nav-tab-part1>
      <div class="main">
        <div class="tip">扫描小程序二维码，开启商品支付</div>
        <div>
          <!-- <img class="images" :src="'data:image/png;base64,' + Qcode" /> -->
          <!-- <img class="images" :src="Qcode" /> -->
          <img class="images" src="@/assets/images/home/codes.jpg" />
        </div>
        <!-- <div>欢迎访问数字矩阵，祝您生活愉快~~~</div> -->
      </div>
      <footer-page></footer-page>
    </div>
  </div>
</template>

<script>
import footerPage from "@/components/common/footer/footerPage.vue";
import NavTabPart1 from '../../components/common/nav/navTab_part1.vue';
export default {
  name: "ProductBuy",
  data() {
    return {
      Qcode: "",
    };
  },
  created() {
    this.$axios("/API/GetQRCode_Base64", {
      Version: "",
      Scene:
        "FLnkID=" +
        this.$route.query.FLnkID +
        "&TypeGuid=" +
        this.$route.query.ServiceTypeGuid,
      Page: "pages/productDetail/productDetail",
    }).then((res) => {
      this.Qcode = res;
      //this.Qcode =this.arrayBufferToBase64(res);
    });
  },
  components: {
    footerPage,
    NavTabPart1
  },
  methods: {
    //字节流转base64
    arrayBufferToBase64(buffer) {
      var binary = "";
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },
    toindex() {
      this.$router.push("/home");
    },
    goBack(){
      this.$router.go(-1)
    }
  },
};
</script>

<style lang="scss" scoped>
@media(min-width: 1201px){
  @import './ProductBuy.scss';
}
@media(max-width: 1200px){
  @import './ProductBuy_phone.scss';
}
</style>
